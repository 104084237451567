import React, {
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import cn from 'classnames'
import { keys } from 'common/utils/forms/keys'
import { toast } from 'components/base/Toast'

export const sizeClassNames = {
  default: '',
  large: 'form-control-lg',
  small: 'form-control-sm',
}

export type InputType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLTextAreaElement
> & {
  isValid?: boolean
  inputClassName?: string
  iconRight?: ReactNode
  iconLeft?: ReactNode
  textarea?: boolean
  textButton?: string
  iconLeftColour?: string
  iconRightColour?: string
  touched?: boolean
  onIconClick?: () => void
  onIconLeftClick?: () => void
  onIconRightClick?: () => void
  shouldValidate?: boolean
  size?: keyof typeof sizeClassNames
}

const Input: FC<InputType> = ({
  children,
  className,
  disabled,
  iconLeft,
  iconRight,
  id,
  inputClassName,
  isValid = true,
  name,
  onBlur,
  onFocus,
  onIconLeftClick,
  onIconRightClick,
  onKeyDown,
  placeholder = ' ',
  shouldValidate: _shouldValidate,
  size = 'default',
  textarea,
  touched,
  type,
  value,
  ...rest
}) => {
  const [shouldValidate, setShouldValidate] = useState(false)
  useEffect(() => {
    if (_shouldValidate) {
      setShouldValidate(true)
    }
  }, [_shouldValidate])

  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>()

  const focusHandler: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setIsFocused(true)
    onFocus && onFocus(e)
  }

  const onKeyDownHandler: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (keys.isEscape(e)) {
      ref.current?.blur()
    }
    onKeyDown && onKeyDown(e)
  }

  const blurHandler: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setShouldValidate(true)
    setIsFocused(false)
    onBlur && onBlur(e)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const classNameHandler = cn(
    'position-relative input-container',
    {
      focused: isFocused,
      'input-container--with-left-icon': !!iconLeft,
      'input-container--with-right-icon': !!iconRight || type === 'password',
      invalid: (shouldValidate || touched) && !isValid,
    },
    className,
  )

  const combinedInputClassName = cn(
    {
      error: !(shouldValidate || touched) && !isValid,
      'form-control': true,
      'pe-8': !iconRight && disabled && !!value,
    },
    // @ts-ignore
    sizeClassNames[size],
    inputClassName,
  )

  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <div className={classNameHandler}>
      {textarea ? (
        <textarea
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
          id={id}
          // @ts-ignore
          ref={ref}
          onFocus={focusHandler}
          onKeyDown={onKeyDownHandler}
          onBlur={blurHandler}
          value={value}
          className={combinedInputClassName}
        />
      ) : (
        <>
          {!!iconLeft && (
            <span
              onClick={onIconLeftClick}
              className={cn('input-icon icon-left', {
                'icon-disabled': disabled,
                'pe-none': !onIconLeftClick,
              })}
            >
              {iconLeft}
            </span>
          )}
          <input
            disabled={disabled}
            name={name}
            type={type === 'password' && showPassword ? '' : type}
            {...rest}
            id={id}
            // @ts-ignore
            ref={ref}
            onFocus={focusHandler}
            onKeyDown={onKeyDownHandler}
            onBlur={blurHandler}
            value={value}
            placeholder={placeholder}
            className={combinedInputClassName}
          />

          {iconRight ? (
            <span
              onClick={onIconRightClick}
              className={cn('input-icon icon-right', {
                'icon-disabled': disabled,
                'pe-none': !onIconRightClick,
              })}
            >
              {iconRight}
            </span>
          ) : (
            type === 'password' && (
              <i
                onClick={togglePasswordVisibility}
                className={cn('input-icon icon-right', {
                  'fas fa-eye': !showPassword,
                  'fas fa-eye-slash': showPassword,
                  'icon-disabled': disabled,
                })}
              />
            )
          )}
          {!iconRight && disabled && !!value && (
            <span
              onClick={() => {
                navigator.clipboard.writeText(value)
                toast('Copied to clipboard')
              }}
              className={cn('input-icon cursor-pointer icon-right', {})}
            >
              <span className='fa cursor-pointer fa-copy' />
            </span>
          )}
        </>
      )}
      {children && children}
    </div>
  )
}

Input.displayName = 'Input'
export default Input
